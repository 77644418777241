<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-9 col-xl-9">
        <async-object
          should-fetch
          :fetch-method="fetchData"
        >
          <div class="card mb-4">
            <div class="card-header">Type d'entreprise</div>
            <div class="card-body">
              <label class="alert alert-info" v-if="false">
                <!-- TODO: Update text here -->
                Il n'est pas possible de modifier le type d'entreprise une fois configuré.
                Assurez-vous que le type représente correctement votre entreprise.
                <v-popover class="d-inline-block" trigger="hover">
                  <fa-icon size="sm" class="ml-1" :icon="['fas', 'info-circle']"></fa-icon>
                  <span slot="popover">
                    Contactez-nous pour plus d'assistance.
                  </span>
                </v-popover>
              </label>
              <div class="form-group w-100">
                <select
                  :disabled="!!profile.businessType && !!$store.state.profile.data"
                  id="businessType-selector"
                  class="custom-select"
                  :value="profile.businessType"
                  @change="(event) => handleForm('businessType', event.target.value)"
                  :class="{'border-danger': showErrors && !!errors.businessType}"
                >
                  <option value=""></option>
                  <option value="individual">Entreprise individuelle</option>
                  <option value="company">Compagnie</option>
                </select>
                <div v-if="showErrors && !!errors.businessType" class="invalid-feedback">
                  {{ errors.businessType[0] }}
                </div>
              </div>
            </div>
          </div>

          <transition name="fade">
            <div class="card mb-4" v-if="!!profile.businessType  && profile.businessType === 'individual'">
              <div class="card-header">
                Renseignements sur l'entrepreneur
                <span class="text-danger">*</span>
              </div>
              <div class="card-body">
                <div class="d-flex flex-column flex-sm-row">
                  <div class="form-group w-100 pr-sm-2">
                    <label for="profile-firstName">Prénom</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.firstName }"
                      id="profile-firstName"
                      :value="profile.firstName"
                      @input="(event) => handleForm('firstName', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.firstName" class="invalid-feedback">
                      {{ errors.firstName[0] }}
                    </div>
                  </div>
                  <div class="form-group w-100 pl-sm-2">
                    <label for="profile-lastName">Nom</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.lastName }"
                      id="profile-lastName"
                      :value="profile.lastName"
                      @input="(event) => handleForm('lastName', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.lastName" class="invalid-feedback">
                      {{ errors.lastName[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group w-50 pr-2">
                  <label for="profile-birthday">Date de naissance</label>
                  <the-mask
                    id="profile-birthday"
                    mask="##/##/####"
                    masked
                    type="text"
                    maxlength="10"
                    class="form-control w-100"
                    :class="{ 'is-invalid': showErrors && !!errors.dateOfBirth }"
                    :value="profile.dateOfBirth"
                    placeholder="jj/mm/aaaa"
                    @input="(value) => handleForm('dateOfBirth', value)"
                  ></the-mask>
                  <div v-if="showErrors && !!errors.dateOfBirth" class="invalid-feedback">
                    {{ errors.dateOfBirth[0] }}
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div class="card mb-4" v-if="!!profile.businessType && profile.businessType === 'company'">
              <div class="card-header">
                Renseignements sur la compagnie
                <span class="text-danger">*</span>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label for="profile-companyName">Nom de la compagine</label>
                  <input
                    type="text"
                    class="form-control w-100"
                    :class="{ 'is-invalid': showErrors && !!errors.companyName }"
                    id="profile-companyName"
                    :value="profile.companyName"
                    @input="(event) => handleForm('companyName', event.target.value)"
                  >
                  <div v-if="showErrors && !!errors.companyName" class="invalid-feedback">
                    {{ errors.companyName[0] }}
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row">
                  <div class="form-group w-100 pr-sm-2">
                    <label for="profile-federalNumber">Numéro d'entreprise fédérale</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.federalCompanyNumber }"
                      id="profile-federalNumber"
                      :value="profile.federalCompanyNumber"
                      @input="(event) => handleForm('federalCompanyNumber', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.federalCompanyNumber" class="invalid-feedback">
                      {{ errors.federalCompanyNumber[0] }}
                    </div>
                  </div>

                  <div class="form-group w-100 pl-sm-2">
                    <label for="profile-provincialNumber">Numéro d'entreprise provincial</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.provincialCompanyNumber }"
                      id="profile-provincialNumber"
                      :value="profile.provincialCompanyNumber"
                      @input="(event) => handleForm('provincialCompanyNumber', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.provincialCompanyNumber" class="invalid-feedback">
                      {{ errors.provincialCompanyNumber[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div class="card mb-4" v-if="!!profile.businessType">
              <div class="card-header">
                Adresse de l'entreprise
                <span class="text-danger">*</span>
              </div>
              <div class="card-body">
                <div class="d-flex flex-column flex-sm-row">
                  <div class="form-group w-100 pr-sm-2">
                    <label for="profile-address">Adresse</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.address }"
                      id="profile-address"
                      :value="profile.address"
                      @input="(event) => handleForm('address', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.address" class="invalid-feedback">
                      {{ errors.address[0] }}
                    </div>
                  </div>
                  <div class="form-group w-100 pl-sm-2">
                    <label for="profile-address2">Adresse 2</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      id="profile-address2"
                      :value="profile.address2"
                      @input="(event) => handleForm('address2', event.target.value)"
                    >
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row">
                  <div class="form-group mobile-wid-100 w-75 pr-sm-2">
                    <label for="profile-provincialNumber">Ville</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.city }"
                      id="profile-city"
                      :value="profile.city"
                      @input="(event) => handleForm('city', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.city" class="invalid-feedback">
                      {{ errors.city[0] }}
                    </div>
                  </div>

                  <div class="form-group mobile-wid-100 w-25 pl-sm-2">
                    <label for="profile-postalCode">Code postal</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.postalCode }"
                      id="profile-postalCode"
                      :value="profile.postalCode"
                      @input="(event) => handleForm('postalCode', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.postalCode" class="invalid-feedback">
                      {{ errors.postalCode[0] }}
                    </div>
                  </div>
                </div>

                <div class="d-flex flex-column flex-sm-row">
                  <div class="form-group w-100 pr-sm-2">
                    <label for="profile-federalNumber">Province</label>
                    <province-selector
                      class="form-control w-100"
                      input-id="profile-province"
                      id="profile-province"
                      :value="profile.province"
                      @value-change="(value) => handleForm('province', value)"
                      :has-error="showErrors && !!errors.province"
                    />
                    <div v-if="showErrors && !!errors.province" class="invalid-feedback">
                      {{ errors.province[0] }}
                    </div>
                  </div>
                  <div class="form-group w-100 pl-sm-2">
                    <label for="profile-federalNumber">Pays</label>
                    <country-selector
                      class="form-control w-100"
                      input-id="profile-province"
                      id="profile-country"
                      :value="profile.country"
                      @value-change="(value) => handleForm('country', value)"
                      :has-error="showErrors && !!errors.country"
                    />
                    <div v-if="showErrors && !!errors.country" class="invalid-feedback">
                      {{ errors.country[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div class="card mb-4" v-if="!!profile.businessType  && profile.businessType === 'company'">
              <div class="card-header">
                Représentant légal de la compagnie
                <span class="text-danger">*</span>
              </div>
              <div class="card-body">
                <div class="d-flex">
                  <div class="form-group w-100 pr-2">
                    <label for="profile-firstName-2">Prénom</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.firstName }"
                      id="profile-firstName-2"
                      :value="profile.firstName"
                      @input="(event) => handleForm('firstName', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.firstName" class="invalid-feedback">
                      {{ errors.firstName[0] }}
                    </div>
                  </div>
                  <div class="form-group w-100 pl-2">
                    <label for="profile-lastName-2">Nom</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.lastName }"
                      id="profile-lastName-2"
                      :value="profile.lastName"
                      @input="(event) => handleForm('lastName', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.lastName" class="invalid-feedback">
                      {{ errors.lastName[0] }}
                    </div>
                  </div>
                </div>

                <div class="form-group w-50 pr-2">
                  <label for="profile-birthday-2">Date de naissance</label>
                  <the-mask
                    id="profile-birthday-2"
                    mask="##/##/####"
                    masked
                    type="text"
                    maxlength="10"
                    class="form-control w-100"
                    :class="{ 'is-invalid': showErrors && !!errors.dateOfBirth }"
                    :value="profile.dateOfBirth"
                    placeholder="jj/mm/aaaa"
                    @input="(value) => handleForm('dateOfBirth', value)"
                  ></the-mask>
                  <div v-if="showErrors && !!errors.dateOfBirth" class="invalid-feedback">
                    {{ errors.dateOfBirth[0] }}
                  </div>
                </div>

                <div class="d-flex">
                  <div class="form-group w-100 pr-2">
                    <label for="rep-address">Adresse</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.representativeAddress }"
                      id="rep-address"
                      :value="profile.representativeAddress"
                      @input="(event) => handleForm('representativeAddress', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.representativeAddress" class="invalid-feedback">
                      {{ errors.representativeAddress[0] }}
                    </div>
                  </div>

                  <div class="form-group w-100 pl-2">
                    <label for="rep-address2">Adresse 2</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      id="rep-address2"
                      :value="profile.representativeAddress2"
                      @input="(event) => handleForm('representativeAddress2', event.target.value)"
                    >
                  </div>
                </div>

                <div class="d-flex">
                  <div class="form-group w-75 pr-2">
                    <label for="rep-city">Ville</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.representativeCity }"
                      id="rep-city"
                      :value="profile.representativeCity"
                      @input="(event) => handleForm('representativeCity', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.representativeCity" class="invalid-feedback">
                      {{ errors.representativeCity[0] }}
                    </div>
                  </div>

                  <div class="form-group w-25 pl-2">
                    <label for="rep-postalCode">Code postal</label>
                    <input
                      type="text"
                      class="form-control w-100"
                      :class="{ 'is-invalid': showErrors && !!errors.representativePostalCode }"
                      id="rep-postalCode"
                      :value="profile.representativePostalCode"
                      @input="(event) => handleForm('representativePostalCode', event.target.value)"
                    >
                    <div v-if="showErrors && !!errors.representativePostalCode" class="invalid-feedback">
                      {{ errors.representativePostalCode[0] }}
                    </div>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="form-group w-100 pr-2">
                    <label for="rep-province">Province</label>
                    <province-selector
                      class="form-control w-100"
                      input-id="profile-province"
                      id="rep-province"
                      :value="profile.representativeProvince"
                      @value-change="(value) => handleForm('representativeProvince', value)"
                      :has-error="showErrors && !!errors.representativeProvince"
                    />
                    <div v-if="showErrors && !!errors.representativeProvince" class="invalid-feedback">
                      {{ errors.representativeProvince[0] }}
                    </div>
                  </div>
                  <div class="form-group w-100 pl-2">
                    <label for="rep-country">Pays</label>
                    <country-selector
                      class="form-control w-100"
                      input-id="profile-province"
                      id="rep-country"
                      :value="profile.representativeCountry"
                      @value-change="(value) => handleForm('representativeCountry', value)"
                      :has-error="showErrors && !!errors.representativeCountry"
                    />
                    <div v-if="showErrors && !!errors.representativeCountry" class="invalid-feedback">
                      {{ errors.representativeCountry[0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>

          <transition name="fade">
            <div class="my-4" v-if="!!this.profile.businessType">
              <button-loader :disabled="isProcessing" :loading="isProcessing" @click="handleSave">
                Enregistrer
              </button-loader>
            </div>
          </transition>
        </async-object>
      </div>
    </div>
  </div>
</template>

<script>
import userData from '@/mixins/user-data'
import useValidator from '@/mixins/useValidator'
import formatsDate from '@/mixins/formatsDate'
import formError from '@/mixins/form-error'
import AsyncObject from '@/components/common/AsyncObject'
import CountrySelector from '@/components/forms/inputs/CountrySelector'
import ProvinceSelector from '@/components/forms/inputs/ProvinceSelector'
import ButtonLoader from '@/components/common/ButtonLoader'
import { TheMask } from 'vue-the-mask'
import { isEmpty } from 'lodash-es'
import { format } from 'date-fns'

export default {
  mixins: [userData, useValidator, formatsDate, formError],
  components: {
    AsyncObject,
    CountrySelector,
    ProvinceSelector,
    TheMask,
    ButtonLoader
  },
  data () {
    return {
      isProcessing: false,
      showErrors: false,
      profile: {}
    }
  },
  watch: {
    '$store.state.profile.data' (data) {
      this.profile = {
        ...data,
        dateOfBirth: data.dateOfBirth ? this.$longUTCDate(data.dateOfBirth) : ''
      } || {}
    }
  },
  computed: {
    validationRules () {
      return {
        businessType: 'required',
        firstName: 'required_if:businessType,individual|min:2',
        lastName: 'required_if:businessType,individual|min:2',
        dateOfBirth: 'required|local-date',
        companyName: 'required_if:businessType,company|min:1|max:200',
        federalCompanyNumber: 'required_if:businessType,company',
        provincialCompanyNumber: 'required_if:businessType,company',
        address: 'required|min:4|max:50',
        city: 'required|min:2',
        postalCode: 'required|ca-postalcode',
        province: 'required|ca-provincialcode',
        country: 'required|ca-countrycode',
        representativeAddress: 'required_if:businessType,company|min:4|max:50',
        representativeCity: 'required_if:businessType,company|min:2',
        representativePostalCode: 'required_if:businessType,company|ca-postalcode',
        representativeProvince: 'required_if:businessType,company|ca-provincialcode',
        representativeCountry: 'required_if:businessType,company|ca-countrycode'
      }
    }
  },
  methods: {
    handleForm (key, value) {
      if (Object.entries(this.profile).length > 0) {
        this.$emit('formStatusChanged', true)
      }
      this.profile = {
        ...this.profile,
        [key]: value
      }
      this.validate(this.profile, this.validationRules)
    },
    async fetchData () {
      try {
        this.isProcessing = true
        await this.$store.dispatch('profile/fetch', this.currentStoreId)
      } catch (error) {
        if (error.response.status === 404) {
          // user needs to create profile
          return
        }
        this.serverError = error
      } finally {
        this.isProcessing = false
      }
    },
    async handleSave () {
      if (!isEmpty(this.errors)) {
        this.showErrors = true
        this.toastFormError()
        return
      }

      try {
        this.showErrors = false
        this.isProcessing = true

        const isoDate = format(this.$parsedDate(this.profile.dateOfBirth), 'yyyy-MM-dd')

        await this.$store.dispatch('profile/update', {
          storeId: this.currentStoreId,
          payload: { ...this.profile, dateOfBirth: isoDate }
        })

        this.$emit('formStatusChanged', false)
        this.$emit('saved')

        this.$toasted.success('Paramètres enregistrés avec succès.')
      } catch (error) {
        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      } finally {
        this.isProcessing = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
  font-size: 13px;
}
</style>
