<template>
  <div class="mt-3 p-3 border rounded w-100 d-flex flex-column justify-content-center align-items-center">
    <p class="text-muted">
      *Visitez <a href="" @click.prevent="handleClick">notre FAQ</a> pour plus d'information concernant l'utilisation des données liées à vos informations bancaires.
    </p>
    <a href="https://stripe.com/en-ca" target="_blank" class="d-flex align-items-center">
      Informations bancaires sécurisées par <img width="64" src="@/assets/images/icons/stripe.svg" alt="Stripe">
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick () {
      this.$emit('click')
      this.$router.push('/faqs')
    }
  }
}
</script>
